.animate.show {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.animate {
  opacity: 0;
  transform: translateY(50px);
}
.third-page {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}
@media screen and (min-width: 640px) {
  .text-center {
    max-width: 80%;
    margin: 0 auto;
  }

  .text-center p {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .text-center img {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 768px) {
  .text-center {
    max-width: 70%;
    margin: 0 auto;
  }

  .text-center p {
    max-width: 800px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.third-page.show {
  opacity: 1;
  transform: translateY(0);
}

.third-page img {
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.third-page.show img {
  opacity: 1;
  transform: translateX(0);
}
