.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.animate.show {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.animate {
  opacity: 0;
  transform: translateY(50px);
}
@media screen and (min-width: 640px) {
  .images-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 768px) {
  .images-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

